/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css"; // Find all the icons here: https://icons.getbootstrap.com/

@import "primeng/resources/themes/aura-light-blue/theme.css";
@import "primeng/resources/primeng.min.css";
@import "primeicons/primeicons.css";

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

@import "./primeNGOverrides.scss";

:root {
  --bs-font-sans-serif: "Noto Sans", sans-serif;
  --font-family: "Noto Sans", sans-serif;

  --primary: #e6f9ff;
  --primary-dark: #1b2c5a;

  h2 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 0;
  }

  h4 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 0;
  }
}

body {
  margin: 0;
}
